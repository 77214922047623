<template>
  <div>
    <h1>Встреча {{ my_id }}</h1>
    <ButtonUI v-if="!my_stream" :loading="!sfu" @click="join">Подключиться</ButtonUI>
    <video playsinline ref="local_video" autoplay height="400" muted="true" width="400"></video>
    <div class="members">
      <div v-for="(member, i) in members" :key="i" class="member">
        <div class="member__caption">
          {{ member.display }} / {{ member.bitrate }}
        </div>
        <video v-if="member.stream" :srcObject.prop="member.stream" autoplay playsinline height="240"
               width="320"></video>
        <v-progress-circular v-else
                             class="mr-2"
                             style="position: absolute; left: 1em; top: 50%; transform: translateY(-50%); z-index: 1000"
                             :width="1"
                             color="white"
                             :size="20"
                             indeterminate
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import {Janus} from "janus-gateway"
import ButtonUI from "@/components/UI/ButtonUI.vue";

export default {
  name: "Meetings",
  components: {ButtonUI},
  data() {
    return {
      janus: null,
      sfu: null,
      my_stream: null,
      room_id: 1234,
      my_id: null,
      remote_feeds: {},
      remote_streams: [],
      members: []
    }
  },
  methods: {
    join() {
      let register = {
        request: "join",
        room: this.room_id,
        ptype: "publisher",
        display: this.$store.state.user.currentUser.name
      };
      this.sfu.send({message: register});
    },

    broadcastYourself() {
      this.sfu.createOffer({
        media: {
          screenshareFrameRate: 60,
          video: "screen",
          audioRecv: false,
          videoRecv: false,
          audioSend: true,
          videoSend: true
        },
        simulcast: false,
        success: (jsep) => {
          let publish = {request: "configure", audio: true, video: true};
          this.sfu.send({message: publish, jsep: jsep})
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    attachSome(id, display, audio, video) {
      this.janus.attach({
        plugin: "janus.plugin.videoroom",
        opaqueId: 'videoroom-' + this.$store.state.user.currentUser.id,
        success: (remoteFeed) => {
          this.remote_feeds[id] = remoteFeed
          remoteFeed.simulcastStarted = false;
          var subscribe = {
            request: "join",
            room: this.room_id,
            ptype: "subscriber",
            feed: id,
            private_id: this.my_id
          };

          if (Janus.webRTCAdapter.browserDetails.browser === "safari" &&
              (video === "vp9" || (video === "vp8" && !Janus.safariVp8))) {
            if (video)
              video = video.toUpperCase()
            subscribe.offer_video = false;
          }
          remoteFeed.videoCodec = video;
          remoteFeed.send({message: subscribe});
        },
        onremotestream: (stream) => {
          this.members = this.members.map((member) => {
            if (member.id === id) {
              member.stream = stream;
            }
            return member;
          });
        },
        onmessage: (msg, jsep) => {
          var event = msg["videoroom"];
          if (event === 'attached') {
            this.members = [...this.members, msg];
            new Audio('/notification.mp3').play();
          }
          if (jsep) {
            this.remote_feeds[id].createAnswer(
                {
                  jsep: jsep,
                  // Add data:true here if you want to subscribe to datachannels as well
                  // (obviously only works if the publisher offered them in the first place)
                  media: {audioSend: false, videoSend: false},	// We want recvonly audio/video
                  success: (jsep) => {
                    Janus.debug("Got SDP!", jsep);
                    let body = {request: "start", room: this.room_id};
                    this.remote_feeds[id].send({message: body, jsep: jsep});
                  },
                  error: function (error) {
                    Janus.error("WebRTC error:", error);
                  }
                });
          }
        }
      })
    },
    attachPublishers(publishers) {
      publishers.forEach((p) => {
        this.attachSome(p.id, p.display, true, true)
      });
    },
    removeMember(id) {
      new Audio('/notification.mp3').play();
      this.remote_feeds[id].detach();
      this.members = this.members.filter((member) => member.id !== id);
    },
    attachYourSelf() {
      this.janus.attach({
        plugin: 'janus.plugin.videoroom',
        opaqueId: 'videoroom-' + this.$store.state.user.currentUser.id,
        success: (sfu) => {
          this.sfu = sfu;
        },
        onmessage: (msg, jsep) => {
          const event = msg["videoroom"];
          // eslint-disable-next-line no-console
          console.log(event)
          if (event === 'joined') {
            this.my_id = msg['private_id']
            // eslint-disable-next-line no-console
            this.attachPublishers(msg.publishers);
            this.broadcastYourself()
          } else if (event === 'event' && msg.publishers) {
            this.attachPublishers(msg.publishers);
          } else if (msg.leaving) {
            this.removeMember(msg.leaving);
          }
          if (jsep) {
            Janus.debug("Handling SDP as well...", jsep);
            this.sfu.handleRemoteJsep({jsep: jsep});
          }
        },
        webrtcState: function (on) {
          Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
        },
        iceState: function (state) {
          Janus.log("ICE state changed to " + state);
        },
        onlocalstream: (stream) => {
          // eslint-disable-next-line no-console
          console.log(stream);
          this.my_stream = stream;
          Janus.attachMediaStream(this.$refs.local_video, stream);
        },
        mediaState: function (medium, on) {
          Janus.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium);
        },
      })
    }


  },
  mounted() {
    Janus.init({
      debug: "all", callback: () => {
        Janus.isWebrtcSupported();
        this.janus = new Janus({
          server: "https://kurento.osipov.digital/janus",
          success: () => {
            this.attachYourSelf();

            setInterval(() => {
              this.members = this.members.map((member) => {
                let id = member.id;
                const feed = this.remote_feeds[id];
                if (id && feed) {
                  member.bitrate = feed.getBitrate();
                  // eslint-disable-next-line no-console
                  console.log(feed.getBitrate())
                }
                return member;
              });
            }, 1000)
          },
        });
      }
    });
  }
}
</script>

<style scoped>
.member {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: 320px;
  height: 240px;
  margin-right: 10px;
  border: 1px solid #eaeaea;
}

.member__caption {
  position: absolute;
  backdrop-filter: blur(10px);
  width: 100%;
  padding: 5px;
}

.members {
  display: flex;
  flex-wrap: wrap;
}
</style>
